import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import Menu from "./Menu";
import Header from "./Header";
import Footer from "./Footer";
import "./App.css";

class App extends React.Component {
    render() {
        return (
            <div>
                <BrowserRouter>
                    <div>
                        <Menu />
                        <Header />
                        <div className="wrapper">
                            <Route path="/" exact component={Home} />
                            <Route path="/contato" component={Contact} />
                            <Footer />
                        </div>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
