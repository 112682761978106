import React from "react";
import "./Home.css";
class Home extends React.Component {
    render() {
        return (
            <div className="apresentacao"> 
                <h1>Olá!</h1>
            
                <p>
                Provavelmente você já deve ter ouvido (ou até falado) algo do tipo: “para que ir ao psicólogo, se eu posso desabafar com um amigo?” Como se fazer psicoterapia fosse, simplesmente encontrar um amigo na mesa de um bar, jogar meia hora de conversa fora e pronto, os problemas acabaram! Você pode também já ter ouvido que “psicólogo é uma pessoa que fala coisas bonitinhas pra você ficar bem” ou clichês como “psicólogo é médico de louco; usa divã no tratamento;  é desnecessário; não vale a pena; é demorado; etc…”. Sim, isso tudo é equivocado e muita gente ainda ainda pensa dessa maneira. 
</p>
<p>Não é difícil nos depararmos com pessoas que afirmam precisar de psicoterapia, porém não buscam um profissional por se sentirem constrangidos, reprimidos pelo preconceito e desinformação. Procurar ajuda psicológica tem ganhou sentido pejorativo, diminuindo o trabalho de quem o faz e fazendo com que quem precise sinta vergonha disso: “vá ao psicólogo, você está maluco!”. Essa incoerência se dá pelo resquício que ainda sofre nossa sociedade, da época em que se estigmatizou bastante a área da saúde mental.
Diante disso tudo, resolvi com este texto lhe convidar a rever seus conceitos e começar a trilhar um novo caminho daqui pra frente. E aí, você topa? </p>
<p>
Então vamos lá!
Em primeiro lugar, quero ressaltar que essas ideias contraditórias podem facilmente ser desmistificadas se observarmos bem a prática da psicologia. Afinal, qual seria o motivo de existir uma profissão que trabalha tal qual seu amigo o faz (de graça), ou na qual o profissional fala exatamente o que você já sabe, precisa ou quer ouvir? Anos de estudo e formação, nos mantendo sempre atualizados e nunca parando de estudar não são para nada.
</p>
<p>Então quero lhes apresentar a Psicologia, essa profissão que abracei com muito amor. O objetivo de um psicólogo não é ser mais um amigo, mas sim agir de acordo com técnicas e procedimentos profissionais, aquelas que nós psicólogos desenvolvemos ao longo da jornada acadêmica e profissional. Não desmerecendo amizades, é muito bom ter amigos com os quais a gente possa sentar junto e falar tudo que der vontade, desabafar e até chorar quando necessário. Apesar do efeito psicoterápico paliativo que possui, uma conversa com um amigo não é uma sessão de psicoterapia. Nós psicólogos temos a função de escutar atentamente seu relato, analisar seus conflitos, seus pontos fracos e principalmente seus pontos fortes (que com certeza serão muitos). A partir daí, com base nos estudos e funcionamento da mente humana, podemos dar o suporte necessário e mostrar do que você é capaz, até que você consiga ressignificar a situação e conduzir a vida de maneira mais satisfatória. 
</p>
<p>Quando você apenas desabafa com alguém, lê livros de auto-ajuda e procura religião, você não está encontrando uma visão exterior dos seus problemas. Nós seres humanos possuímos muitos mecanismos psicológicos de defesa que, uma vez mal utilizados, provocam em nós mesmos o efeito auto-sabotagem ou nos acomoda na posição de vítimas. Isso pode resultar em mais sofrimento e aumentar o emaranhado de conflitos. O psicólogo não toma partido quando o assunto é os seus problemas, vendo o conflito de todos os ângulos possíveis e mostrando isso a você.
</p>
<p>O psicólogo atende quem está passando por um conflito psicológico, independentemente de ser patológico ou não. Não é necessário estar doente para precisar de ajuda. É necessário querer sair do lugar e mudar de vida. O quanto você está disposto a pagar por coisas que muitas vezes lhe servem tão pouco e o quanto você está disposto a investir em algo que lhe trará mudanças claras e importantes?</p>
<p>O seu tratamento é uma experiência que lhe põe a pensar, te dá novas ideias, aponta possibilidades e encaminha para a vida, é um investimento na qualidade de vida.</p>
<p>
E aí, para que ir ao psicólogo?</p>
<p>Quando sentir que sua vida travou, não desanime, não desista, não se sabote. Busque um psicólogo que trabalhe com uma abordagem adequada ao seu caso, fale dos seus problemas, invista em você. Afinal, quem nesse mundo poderia ser mais importante para você, do que você mesmo?</p>
<p>Pense Nisso!</p>

<p className="itonome">Wilton Rocha</p>

<hr />
<div className="apresenta">Wilton Rocha é Psicólogo Clínico, atuante como Psicoterapeuta de Casal e Família. Seu trabalho tem como foco analisar e compreender melhor o modus operandi dos seus pacientes, no que diz respeito aos seus aspectos biológicos, afetivos, cognitivos e sociais, buscando sempre amenizar suas dores e viabilizar as mudanças necessárias à conquista do seu bem-estar e sua saúde mental.

Quem cuida da mente e das emoções, cuida da vida. Invista em você, faça terapia!</div>

                <hr className="separator" />
                
            </div>
        );
    }
}

export default Home;
