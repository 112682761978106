import React from "react";
import "./Contact.css";

class Contact extends React.Component {
    render() {
        return (
            <div>
                <p>
                    <p>Dúvidas, curiosidades, quer agendar uma consulta ou me contratar?</p>
                    Entre em contato comigo através do e-mail contato@wiltonrochapsi.com 
                </p>
            </div>
        );
    }
}

export default Contact;
