import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <div>
            <div className="social-icons">
                <div>
                    <span>
                        <div>
                        <span>Para ver meu histórico profissional completo, clique aqui:</span>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.linkedin.com/in/wiltonrochapsi/"
                        >
                            <i className="fab fa-linkedin social-icon" />
                        </a>
                        </div>
                        <div>
                            <p>
                                <span className="instagramicone">Para me acompanhar no Instagram, clique aqui:</span> 
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://instagram.com/wiltonrocha.psi/"
                                >
                                    <i className="fab fa-instagram social-icon" />
                                </a>
                            </p>
                        </div> 
                    </span>
                </div>
                <div className="copyright">
                    © Copyright 2019 - Todos os direitos reservados - Wilton Rocha.
                </div>
            </div>
        </div>
    );
};

export default Footer;
