import React from "react";
import { Link } from "react-router-dom";
import "./Menu.css";

const Menu = () => {
    return (
        <div className="main-menu">
            <div className="ui stackable container menu borderless">
                <div className="item section">
                    <Link to="/">Página Principal</Link>
                </div>
                <div className="right menu">
                    <div className="item section">
                        <Link to="/contato">Contato</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;
