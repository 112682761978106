import React from "react";
import "./Header.css";

const Header = () => {
    return (
        <div className="header-main">
            <div className="ui four column container">
                <img
                    className="ui centered small circular image avatar-picture"
                    alt="profile pic"
                    src="/avatar.jpg"
                />
                <div>
                    <p className="text-unit text-name">
                        Wilton Rocha
                    </p>
                    <p className="text-unit title">
                        PSICÓLOGO CLÍNICO E PSICOTERAPEUTA
                    </p>
                </div>
                <hr className="separator" />
            </div>
        </div>
    );
};

export default Header;
